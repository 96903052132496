import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import Themes from './themes';
import '@elastic/eui/dist/eui_theme_light.css';
import App from './components/App';
import { LayoutProvider } from './context/LayoutContext';
import { AuthProvider } from 'react-oidc-context';
import { userManager } from './services/GatekeeperService';

ReactDOM.render(
  <AuthProvider
    userManager={userManager}
    onSigninCallback={() => {
      window.history.replaceState(
        {},
        document.title,
        window.location.pathname + '#/home'
      );
    }}
  >
    <LayoutProvider>
      <ThemeProvider theme={Themes.default}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </LayoutProvider>
  </AuthProvider>,
  document.getElementById('root')
);
