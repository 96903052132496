import React, { useState, useEffect, useCallback, memo, useRef } from 'react';
import { ShowAlert } from '../../components/Common';
import {
  EuiForm,
  EuiPageContent,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiTitle,
  EuiPageContentBody,
  EuiFormRow,
  EuiBasicTable,
} from '@elastic/eui';
import { getUsers, deleteUser } from '../../services/GatekeeperService';

const UserList = memo(
  ({ users, userColumns, onTableChange, tableRef, pagination, sorting }) => {
    return (
      <>
        <EuiForm component="form">
          <EuiFormRow label="Users" fullWidth>
            <EuiBasicTable
              itemId="id"
              isSelectable={true}
              items={users}
              columns={userColumns}
              onChange={onTableChange}
              ref={tableRef}
              pagination={pagination}
              sorting={sorting}
            />
          </EuiFormRow>
        </EuiForm>
      </>
    );
  }
);

const Users = () => {
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('info');

  const [users, setUsers] = useState([]);
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortField, setSortField] = useState('username');
  const tableRef = useRef();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const loadUsers = useCallback(async () => {
    const response = await getUsers();
    if (response) {
      const users = response.map((user) => {
        return {
          id: user.kc_id,
          email: user.email,
          groupname: user.groups
            .map((element) => {
              const { group } = element;
              return group.name;
            })
            .join(', '),
          rolename: user.roles.map((element) => {
            const { role } = element;
            return role.name;
          }),
        };
      });
      setUsers(users);
    }
  }, [getUsers]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const onDeleteUser = async (id) => {
    if (id) {
      const response = await deleteUser(id);
      if (!response) {
        setAlertMessage('User has not been deleted.');
        setSeverity('error');
        setOpen(true);
      } else {
        setAlertMessage('User has been deleted.');
        setSeverity('success');
        setOpen(true);
        await loadUsers();
      }
    }
  };

  const userActions = [
    {
      name: 'Delete',
      description: 'Delete this user',
      icon: 'trash',
      type: 'icon',
      color: 'danger',
      onClick: async (user) => {
        onDeleteUser(user.id);
      },
    },
  ];

  const totalItemCount = typeof USERS === typeof undefined ? 0 : users.length;

  const pagination = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    totalItemCount: totalItemCount,
    pageSizeOptions: [3, 5, 8],
  };

  const sorting = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
  };

  const userColumns = [
    { field: 'email', name: 'Email' },
    { field: 'groupname', name: 'Groups' },
    { field: 'rolename', name: 'Roles' },
    { name: 'Actions', actions: userActions },
  ];

  const onTableChange = ({ page = {}, sort = {} }) => {
    const { index: pageIndex, size: pageSize } = page;

    const { field: sortField, direction: sortDirection } = sort;

    setPageIndex(pageIndex);
    setPageSize(pageSize);
    setSortField(sortField);
    setSortDirection(sortDirection);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <EuiPageContent>
        <EuiPageContentHeader>
          <EuiPageContentHeaderSection>
            <EuiTitle>
              <h6>Users management</h6>
            </EuiTitle>
          </EuiPageContentHeaderSection>
        </EuiPageContentHeader>
        <EuiPageContentBody>
          <EuiForm>
            <UserList
              users={users}
              userColumns={userColumns}
              onTableChange={onTableChange}
              tableRef={tableRef}
              pagination={pagination}
              sorting={sorting}
            />
          </EuiForm>
        </EuiPageContentBody>
      </EuiPageContent>
      {ShowAlert(open, handleClose, alertMessage, severity)}
    </>
  );
};

export default Users;
