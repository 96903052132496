import React, { useState, Fragment, useEffect, memo, useCallback } from 'react';
import { ShowAlert } from '../../components/Common';
import {
  EuiForm,
  EuiPageContent,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiTitle,
  EuiPageContentBody,
  EuiTabbedContent,
  EuiFormRow,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiButton,
  EuiBasicTable,
  EuiSelectable,
  EuiComboBox,
} from '@elastic/eui';
import {
  createGroup,
  getGroups,
  getUsers,
  deleteGroup,
  addUserToGroup,
  removeUserFromGroup,
} from '../../services/GatekeeperService';

const NewGroupForm = memo(
  ({
    groupNameValue,
    setGroupNameValue,
    groupDescriptionValue,
    setGroupDescriptionValue,
    onSaveGroup,
    groups,
    groupColumns,
  }) => {
    return (
      <>
        <br />
        <EuiForm component="form">
          <EuiFormRow label="Name">
            <EuiFieldText
              value={groupNameValue}
              onChange={(e) => setGroupNameValue(e.target.value)}
            />
          </EuiFormRow>
          <EuiFormRow label="Description">
            <EuiFieldText
              id="descriptionValue"
              value={groupDescriptionValue}
              onChange={(e) => setGroupDescriptionValue(e.target.value)}
            />
          </EuiFormRow>
          <EuiSpacer />
          {
            <EuiButton fill onClick={onSaveGroup}>
              Save
            </EuiButton>
          }
          <EuiSpacer />
          <EuiFormRow fullWidth label="">
            <EuiBasicTable items={groups} columns={groupColumns} />
          </EuiFormRow>
        </EuiForm>
      </>
    );
  }
);

const GroupAssignment = memo(
  ({ groups, setGroups, users, setUsers, onGroupAssignment }) => {
    return (
      <>
        <EuiForm component="form">
          <EuiFlexGroup component="span">
            <EuiFlexItem component="span">
              <EuiFormRow label="Groups" fullWidth>
                <EuiSelectable
                  searchable
                  singleSelection={true}
                  options={groups}
                  onChange={(newOptions) => setGroups(newOptions)}
                >
                  {(list, search) => (
                    <Fragment>
                      {search}
                      {list}
                    </Fragment>
                  )}
                </EuiSelectable>
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem component="span">
              <EuiFormRow label="Users" fullWidth>
                <EuiSelectable
                  searchable
                  options={users}
                  onChange={(newOptions) => setUsers(newOptions)}
                >
                  {(list, search) => (
                    <Fragment>
                      {search}
                      {list}
                    </Fragment>
                  )}
                </EuiSelectable>
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />
          {
            <EuiButton type="submit" onClick={onGroupAssignment} fill>
              Save
            </EuiButton>
          }
        </EuiForm>
      </>
    );
  }
);

const AssignedGroups = memo(
  ({
    groups,
    selectedGroup,
    onSelectedGroup,
    groupedUsers,
    assignedGroupedUserColumns,
  }) => {
    return (
      <>
        <EuiForm component="form">
          <EuiFormRow label="Select specific group">
            <EuiComboBox
              placeholder="Select a group"
              singleSelection={true}
              options={groups}
              selectedOptions={selectedGroup}
              onChange={(e) => {
                onSelectedGroup(e);
              }}
            />
          </EuiFormRow>
          <EuiFormRow label="Assigned policies" fullWidth>
            <EuiBasicTable items={groupedUsers} columns={assignedGroupedUserColumns} />
          </EuiFormRow>
        </EuiForm>
      </>
    );
  }
);

const Groups = () => {
  const [selectedTabNumber, setSelectedTabNumber] = useState(0);
  const [groupNameValue, setGroupNameValue] = useState('');
  const [groupDescriptionValue, setGroupDescriptionValue] = useState('');
  const [groups, setGroups] = useState([]);

  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('info');

  const [users, setUsers] = useState([]);
  const [tblGroups, setTblGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [groupedUsers, setGroupedUsers] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const loadGroups = useCallback(async () => {
    const _groups = await getGroups();
    if (_groups) {
      const grouplist = [];
      for (const group of _groups) {
        grouplist.push({ value: group.id, label: group.name });
      }
      if (grouplist) {
        setTblGroups(grouplist);
      }
      setGroups(_groups);
    }
  }, []);

  const loadUsers = useCallback(async () => {
    const _users = await getUsers();
    const usersList = [];
    if (_users) {
      for (const user of _users) {
        usersList.push({ value: user.id, label: user.email, sub: user.kc_id });
      }
      if (usersList) {
        setUsers(usersList);
      }
    }
  }, []);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      loadGroups();
      loadUsers();
      onSelectedGroup([]);
    }
  }, [loadGroups, loadUsers, refresh, setRefresh]);

  const onDeleteGroup = async (group) => {
    if (group) {
      const result = await deleteGroup(group.id);
      if (result) {
        setAlertMessage('Group has been deleted.');
        setSeverity('success');
        setRefresh(true);
      } else {
        setAlertMessage(`Error: ${result.error}`);
        setSeverity('error');
      }
      setOpen(true);
    }
  };

  const onSaveGroup = async () => {
    if (groupNameValue && groupDescriptionValue) {
      const result = await createGroup(groupNameValue, groupDescriptionValue);
      if (result?.id) {
        setAlertMessage('Group has been created.');
        setSeverity('success');
        setRefresh(true);
      } else {
        setAlertMessage(`Error: ${result.error}`);
        setSeverity('error');
      }
      setOpen(true);
    }
  };

  const onGroupAssignment = async (e) => {
    e.preventDefault();
    const checkedGroups = tblGroups.filter((e) => {
      return e.checked === 'on';
    });

    const checkedUsers = users.filter((e) => {
      return e.checked === 'on';
    });

    for (const user of checkedUsers) {
      for (const group of checkedGroups) {
        const response = await addUserToGroup(user.sub, group.value);
      }
      setRefresh(true);
    }
  };

  const onRemoveUserFromGroup = async (e) => {
    await removeUserFromGroup(e.sub, e.groupId);
    onSelectedGroup([]);
    setRefresh(true);
  };

  const onSelectedGroup = async (e) => {
    if (e.length > 0) {
      const selectedGroup = groups.filter((group) => {
        return group.id === e[0].value;
      });
      const groupUsers = selectedGroup.map((group) => {
        return group.users.map((user) => ({
          email: user.user.email,
          name: group.name,
          groupId: group.id,
          description: group.description,
          sub: user.user.kc_id,
        }));
      });
      setGroupedUsers(groupUsers.flatMap((x) => x));
      setSelectedGroup(e);
    } else {
      setGroupedUsers([]);
      setSelectedGroup([]);
    }
  };

  const groupActions = [
    {
      name: 'Delete',
      description: 'Delete this group',
      icon: 'trash',
      type: 'icon',
      color: 'danger',
      onClick: async (group) => {
        await onDeleteGroup(group);
      },
    },
  ];

  const assignedGroupedUserActions = [
    {
      name: 'Delete',
      description: 'Delete this assignment',
      icon: 'trash',
      type: 'icon',
      color: 'danger',
      onClick: onRemoveUserFromGroup,
    },
  ];

  const groupColumns = [
    { field: 'name', name: 'Group name' },
    { field: 'description', name: 'Group description' },
    { name: 'Actions', actions: groupActions, truncateText: true },
  ];

  const assignedGroupedUserColumns = [
    { field: 'email', name: 'Email' },
    { field: 'name', name: 'Group name' },
    { field: 'description', name: 'Group description' },
    { name: 'Actions', actions: assignedGroupedUserActions },
  ];

  const tabContents = [
    {
      id: 'tab1',
      name: 'New group',
      content: (
        <>
          <NewGroupForm
            groupNameValue={groupNameValue}
            setGroupNameValue={setGroupNameValue}
            groupDescriptionValue={groupDescriptionValue}
            setGroupDescriptionValue={setGroupDescriptionValue}
            onSaveGroup={onSaveGroup}
            groups={groups}
            groupColumns={groupColumns}
          />
        </>
      ),
    },
    {
      id: 'tab2',
      name: 'Group assignment',
      content: (
        <>
          <GroupAssignment
            groups={tblGroups}
            setGroups={setTblGroups}
            users={users}
            setUsers={setUsers}
            onGroupAssignment={onGroupAssignment}
          />
        </>
      ),
    },
    {
      id: 'tab3',
      name: 'Assigned groups',
      content: (
        <>
          <AssignedGroups
            groups={tblGroups}
            selectedGroup={selectedGroup}
            onSelectedGroup={onSelectedGroup}
            groupedUsers={groupedUsers}
            assignedGroupedUserColumns={assignedGroupedUserColumns}
          />
        </>
      ),
    },
  ];

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <EuiPageContent>
        <EuiPageContentHeader>
          <EuiPageContentHeaderSection>
            <EuiTitle>
              <h6>Groups management</h6>
            </EuiTitle>
          </EuiPageContentHeaderSection>
        </EuiPageContentHeader>
        <EuiPageContentBody>
          <EuiForm>
            <EuiForm>
              <EuiTabbedContent
                tabs={tabContents}
                selectedTab={tabContents[selectedTabNumber]}
                onTabClick={(tab) => {
                  setSelectedTabNumber(tabContents.indexOf(tab));
                }}
              />
            </EuiForm>
          </EuiForm>
        </EuiPageContentBody>
      </EuiPageContent>
      {ShowAlert(open, handleClose, alertMessage, severity)}
    </>
  );
};

export default Groups;
