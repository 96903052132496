import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSelectable,
  EuiSpacer,
} from '@elastic/eui';
import React, { Fragment, memo } from 'react';

const PolicySourceAssignment = memo(
  ({
    optPolicies,
    setOptPolicies,
    optSources,
    setOptSources,
    onPolicySourceAssignment,
  }) => {
    return (
      <>
        <EuiForm component="form">
          <EuiFlexGroup component="span">
            <EuiFlexItem component="span">
              <EuiFormRow label="Policies" fullWidth>
                <EuiSelectable
                  searchable
                  singleSelection={true}
                  options={optPolicies}
                  onChange={(newOptions) => setOptPolicies(newOptions)}
                >
                  {(list, search) => (
                    <Fragment>
                      {search}
                      {list}
                    </Fragment>
                  )}
                </EuiSelectable>
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem component="span">
              <EuiFormRow label="Sources" fullWidth>
                <EuiSelectable
                  searchable
                  singleSelection={false}
                  options={optSources}
                  onChange={(newOptions) => setOptSources(newOptions)}
                >
                  {(list, search) => (
                    <Fragment>
                      {search}
                      {list}
                    </Fragment>
                  )}
                </EuiSelectable>
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />
          <EuiButton type="submit" onClick={onPolicySourceAssignment} fill>
            Save
          </EuiButton>
        </EuiForm>
      </>
    );
  }
);

export default PolicySourceAssignment;
