import React from 'react';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SaveIcon from '@material-ui/icons/Save';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const MessageBox = (message, enabled, style) => {
  return (
    <>
      {enabled && (
        <>
          <div className={style.MessageBox}>{message}</div>
        </>
      )}
    </>
  );
};

export const InputFile = (onChange, style) => {
  return (
    <>
      <div className={style.dashedBorder}>
        <label htmlFor="outlined-button-file">
          <input
            className={style.input}
            id="outlined-button-file"
            type="file"
            multiple
            onChange={onChange}
          />
          <Button variant="outlined" component="span" startIcon={<CloudUploadIcon />}>
            télécharger
          </Button>
        </label>
      </div>
    </>
  );
};

export const ActionBar = (enabled, style, onSendClick) => {
  return (
    <>
      {enabled && (
        <>
          <div className={style.ActionBar}>
            <Button
              variant="contained"
              color="primary"
              onClick={onSendClick}
              startIcon={<SaveIcon />}
            >
              Envoyez
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export const ShowAlert = (open, handleClose, message, severity) => {
  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};
