import React, { useState, useCallback, useEffect, memo, useRef } from 'react';
import {
  EuiForm,
  EuiPageContent,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiTitle,
  EuiPageContentBody,
  EuiTabbedContent,
  EuiFormRow,
  EuiFieldText,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiButton,
  EuiFilePicker,
  EuiBasicTable,
  EuiHealth,
  EuiConfirmModal,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButtonEmpty,
  EuiCallOut,
} from '@elastic/eui';

import { ShowAlert } from '../../components/Common';
import ReactJson from '@microlink/react-json-view';
import {
  getUser,
  deleteSource,
  createSource,
  findUserBySub,
  getSources,
} from '../../services/GatekeeperService';

const NewSourceForm = memo(
  ({
    nameValue,
    setNameValue,
    descriptionValue,
    setDescriptionValue,
    onSaveSource,
    onFilePickerChange,
    files,
    renderFiles,
    metaUrfms,
  }) => {
    return (
      <>
        <br />
        <EuiForm component="form">
          <EuiFlexGroup>
            <EuiFlexItem grow={3}>
              <EuiFormRow label="Source or file name">
                <EuiFieldText
                  id="nameValue"
                  value={nameValue || ''}
                  onChange={(e) => setNameValue(e.target.value)}
                />
              </EuiFormRow>
              <EuiFormRow label="Source description">
                <EuiFieldText
                  id="descriptionValue"
                  value={descriptionValue || ''}
                  onChange={(e) => setDescriptionValue(e.target.value)}
                />
              </EuiFormRow>
              <EuiSpacer />
              <EuiFormRow label="Source files">
                <EuiFilePicker
                  id="filePicker1"
                  multiple
                  initialPromptText="Select or drag and drop multiple source files"
                  onChange={(files) => {
                    onFilePickerChange(files);
                  }}
                  display={'large'}
                />
              </EuiFormRow>
              <EuiFormRow label="">
                <EuiText>
                  <h3>Files attached</h3>
                  {renderFiles(files)}
                </EuiText>
              </EuiFormRow>
              <EuiFormRow label="">
                {
                  <EuiButton
                    fill
                    onClick={onSaveSource}
                    disabled={!nameValue || !descriptionValue || !metaUrfms}
                  >
                    Save
                  </EuiButton>
                }
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={3}>
              <>
                <br />
                <ReactJson
                  name="Metadata records"
                  collapsed={true}
                  iconStyle={'triangle'}
                  src={metaUrfms}
                />
              </>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiForm>
      </>
    );
  }
);

const SourcesForm = memo(
  ({
    metaUrfmColumns,
    sources,
    onTableChange,
    tableref,
    pagination,
    sorting,
    selection,
    mergeButton,
  }) => {
    return (
      <>
        <br />
        <EuiForm component="form">
          <EuiFlexGroup alignItems="center">{mergeButton}</EuiFlexGroup>

          <EuiSpacer size="l" />

          <EuiFormRow label="Uploaded sources" fullWidth>
            <EuiBasicTable
              itemId="id"
              isSelectable={true}
              items={sources}
              columns={metaUrfmColumns}
              onChange={onTableChange}
              ref={tableref}
              pagination={pagination}
              sorting={sorting}
              selection={selection}
            />
          </EuiFormRow>
        </EuiForm>
      </>
    );
  }
);

const renderFiles = (files) => {
  if (files.length > 0) {
    return (
      <ul>
        {Object.keys(files).map((item, i) => (
          <li key={i}>
            <strong>{files[item].name}</strong> ({files[item].size} bytes)
          </li>
        ))}
      </ul>
    );
  } else {
    return <p>Please, add some files to upload.</p>;
  }
};
const Sources = () => {
  const [metaUrfms, setMetaUrfms] = useState([]);
  const [selectedTabNumber, setSelectedTabNumber] = useState(0);
  const [files, setFiles] = useState([]);
  const [nameValue, setNameValue] = useState();
  const [descriptionValue, setDescriptionValue] = useState();
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('info');
  const [sources, setSources] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectedSources, setSelectedSources] = useState([]);
  const [sortField, setSortField] = useState('name');
  const tableref = useRef();
  const [source, setSource] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMergeModalVisible, setIsMergeModalVisible] = useState(false);
  const [sourceName, setSourceName] = useState('');
  const [sourceDescription, setSourceDescription] = useState('');

  const onClickMergeSource = () => {
    openMergeSourceModal();
  };

  const renderMergeButton = () => {
    if (selectedSources.length === 0 || selectedSources.length === 1) {
      return;
    }

    return (
      <EuiButton color="secondary" iconType="merge" onClick={onClickMergeSource}>
        Merge and Send {selectedSources.length} Source File(s)
      </EuiButton>
    );
  };

  const onTableChange = ({ page = {}, sort = {} }) => {
    const { index: pageIndex, size: pageSize } = page;

    const { field: sortField, direction: sortDirection } = sort;

    setPageIndex(pageIndex);
    setPageSize(pageSize);
    setSortField(sortField);
    setSortDirection(sortDirection);
  };

  const onSelectionChange = (selectedItems) => {
    setSelectedSources(selectedItems);
  };
  const totalItemCount = typeof sources === ([] || null) ? 0 : sources.length;

  const pagination = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    totalItemCount: totalItemCount,
    pageSizeOptions: [3, 5, 8],
  };

  const selection = {
    selectable: (source) => !source.is_send,
    onSelectionChange: onSelectionChange,
  };

  const sorting = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
  };

  const closeModal = () => setIsModalVisible(false);
  const showModal = () => setIsModalVisible(true);

  const closeMergeSourceModal = () => setIsMergeModalVisible(false);
  const openMergeSourceModal = () => setIsMergeModalVisible(true);

  // delete source from db, elasticsearch and mongodb
  const onDeleteSource = async (source) => {
    setSource(source);
    showModal();
  };

  const onDeleteSourceConfirm = async () => {
    closeModal();
    if (source.id) {
      const response = await deleteSource(source.id);
      setAlertMessage('Source is deleted successfully');
      setSeverity('success');
      setOpen(true);

      await loadSources();
    } else {
      setOpen(true);
      setAlertMessage(
        'While deletion the sources to elastic stack, unexpected error has been occurred.'
      );
      setSeverity('error');
    }
  };

  const onMergeAndSendSourcesConfirmed = async () => {
    if (sourceName && sourceDescription && selectedSources) {
      //const kcId = sessionStorage.getItem('userId');
      // const result = await globalActions.source.mergeAndSendSource(
      //   kcId,
      //   sourceName,
      //   sourceDescription,
      //   selectedSources
      // );
      // if (result.status === 201) {
      //   setOpen(true);
      //   setAlertMessage('Sources are merged and send to elasticsearch successfully !');
      //   setSeverity('success');
      //   await loadSources();
      // } else {
      //   setOpen(true);
      //   setAlertMessage('While merging the sources, unexpected error has been occurred.');
      //   setSeverity('error');
      // }
      // closeMergeSourceModal();
    }
  };

  // const updateSource = async (kc_id, source_id) => {
  //   await globalActions.source.updateSource(kc_id, source_id);
  // };
  // import source document to elasticsearch
  const onSendSource = async (source) => {
    if (sessionStorage.getItem('userId') && source) {
      // const kc_id = sessionStorage.getItem('userId');
      // const source_id = source.id;
      // const updatedSource = await updateSource(kc_id, source_id);
      // if (updatedSource) {
      //   setOpen(true);
      //   setAlertMessage('Source imported to elastic stack successfully');
      //   setSeverity('success');
      //   await loadSources();
      // } else {
      //   setOpen(true);
      //   setAlertMessage(
      //     'While importation the sources to elastic stack, unexpected error has been occurred.'
      //   );
      //   setSeverity('error');
      // }
    }
  };

  const metaUrfmActions = [
    {
      name: 'Send',
      description: 'Send this source',
      icon: 'aggregate',
      type: 'icon',
      enabled: (value) => !value.is_send,
      onClick: onSendSource,
    },
    {
      name: 'Delete',
      description: 'Delete this source',
      icon: 'trash',
      type: 'icon',
      color: 'danger',
      enable: () => false,
      onClick: onDeleteSource,
    },
  ];
  const metaUrfmColumns = [
    { field: 'name', name: 'Source or file name' },
    { field: 'index_id', name: 'Elasticsearch index' },
    { field: 'mng_id', name: 'MongoDb index' },
    { field: 'description', name: 'Source description' },
    {
      field: 'is_send',
      name: 'Status',
      dataType: 'boolean',
      render: (value) => {
        const color = value ? 'success' : 'danger';
        const label = value ? 'sent' : 'unsent';
        return <EuiHealth color={color}>{label}</EuiHealth>;
      },
    },
    { name: 'Actions', actions: metaUrfmActions },
  ];

  const onFilePickerChange = async (files) => {
    if (files.length > 0) {
      setFiles(files);
      await handleSelectedFile(files);
    } else {
      setFiles([]);
      setMetaUrfms([]);
    }
  };

  const handleSelectedFile = async (files) => {
    for (const file of files) {
      const reader = new FileReader();
      await reader.readAsText(file);
      reader.onload = () => handleData(reader.result);
    }
  };

  const handleData = (file) => {
    const { metadataRecords } = JSON.parse(file);
    if (metadataRecords) {
      setMetaUrfms(metadataRecords);
    }
  };

  const onSaveSource = async (e) => {
    e.preventDefault();
    if (nameValue && descriptionValue && metaUrfms) {
      const result = await createSource(metaUrfms, nameValue, descriptionValue);

      if (result?.id) {
        setOpen(true);
        setAlertMessage('Source created.');
        setSeverity('success');
        setNameValue('');
        setDescriptionValue('');
        setFiles([]);
        setMetaUrfms([]);

        await loadSources();
      } else {
        setOpen(true);
        setAlertMessage('Unexpected error while creating source.');
        setSeverity('error');
      }
    }
  };

  const loadSources = useCallback(async () => {
    const userInfo = getUser();
    const sub = userInfo.profile?.sub;
    const user = await findUserBySub(sub);
    const role = user.roles[0].role_id;
    let result;
    if (role === 1) {
      result = await getSources();
    } else {
      result = await getSources();
    }
    if (result) {
      result = result.map((source) => {
        return {
          id: source.id,
          name: source.name,
          description: source.description,
          index_id: source.source_indices[0].index_id,
          mng_id: source.source_indices[0].mng_id,
          is_send: source.source_indices[0].is_send,
        };
      });
      setSources(result);
    }
  }, [getSources, getUser, findUserBySub]);

  useEffect(() => {
    // clean up controller
    let isSubscribed = true;
    if (isSubscribed) {
      loadSources();
    }
    // cancel subscription to useEffect
    return () => (isSubscribed = false);
  }, [loadSources]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const mergeButton = renderMergeButton();

  const tabContents = [
    {
      id: 'tab1',
      name: 'New source',
      content: (
        <>
          <NewSourceForm
            nameValue={nameValue}
            setNameValue={setNameValue}
            descriptionValue={descriptionValue}
            setDescriptionValue={setDescriptionValue}
            onFilePickerChange={onFilePickerChange}
            files={files}
            renderFiles={renderFiles}
            onSaveSource={onSaveSource}
            metaUrfms={metaUrfms}
          />
        </>
      ),
    },
    {
      id: 'tab2',
      name: 'Sources',
      content: (
        <>
          <SourcesForm
            metaUrfmColumns={metaUrfmColumns}
            sources={typeof sources === undefined ? [] : sources}
            onTableChange={onTableChange}
            tableref={tableref}
            pagination={pagination}
            sorting={sorting}
            selection={selection}
            mergeButton={mergeButton}
          />
        </>
      ),
    },
  ];

  const mergeModalForm = (
    <EuiForm>
      <EuiCallOut title="Proceed with caution!" color="warning" iconType="help">
        <p>Source / File Name is unique field for the In-Sylva Search App.</p>
      </EuiCallOut>{' '}
      <br />
      <EuiFormRow label="Source or file name">
        <EuiFieldText
          name="sourceName"
          value={sourceName}
          onChange={(e) => setSourceName(e.target.value)}
        />
      </EuiFormRow>
      <EuiFormRow label="Source description">
        <EuiFieldText
          name="sourceDescription"
          value={sourceDescription}
          onChange={(e) => setSourceDescription(e.target.value)}
        />
      </EuiFormRow>
      <EuiSpacer />
    </EuiForm>
  );

  let mergeSourceModal;
  if (isMergeModalVisible) {
    mergeSourceModal = (
      <EuiOverlayMask>
        <EuiModal onClose={closeMergeSourceModal} initialFocus="[name=popswitch]">
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              Merge and send selected source files
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>{mergeModalForm}</EuiModalBody>

          <EuiModalFooter>
            <EuiButtonEmpty onClick={closeMergeSourceModal}>Cancel</EuiButtonEmpty>

            <EuiButton onClick={onMergeAndSendSourcesConfirmed} fill>
              Save
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    );
  }

  let Warningmodal;
  if (isModalVisible) {
    Warningmodal = (
      <EuiOverlayMask>
        <EuiConfirmModal
          title="Warning"
          onCancel={closeModal}
          onConfirm={onDeleteSourceConfirm}
          cancelButtonText="Cancel"
          confirmButtonText="Confirm"
          buttonColor="danger"
          defaultFocusedButton="confirm"
        >
          <p>Are you sure you want to delete this file?</p>
        </EuiConfirmModal>
      </EuiOverlayMask>
    );
  }

  return (
    <>
      <EuiPageContent>
        <EuiPageContentHeader>
          <EuiPageContentHeaderSection>
            <EuiTitle>
              <h6>Source management</h6>
            </EuiTitle>
          </EuiPageContentHeaderSection>
        </EuiPageContentHeader>
        <EuiPageContentBody>
          <EuiForm>
            <EuiForm>
              <EuiTabbedContent
                tabs={tabContents}
                selectedTab={tabContents[selectedTabNumber]}
                onTabClick={(tab) => {
                  setSelectedTabNumber(tabContents.indexOf(tab));
                }}
              />
            </EuiForm>
          </EuiForm>
        </EuiPageContentBody>
      </EuiPageContent>
      {ShowAlert(open, handleClose, alertMessage, severity)}
      {Warningmodal}
      {mergeSourceModal}
    </>
  );
};

export default Sources;
