import React from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import Layout from './Layout';
import Error404 from '../pages/error/404';
import Error403 from '../pages/error/403';

export default function App() {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/home" />} />
        <Route path="/not-found" component={Error404} />
        <Route path="/forbidden" component={Error403} />
        <Route path="/" component={Layout} />
        <Route render={() => <Redirect to="/not-found" />} />
      </Switch>
    </HashRouter>
  );
}
