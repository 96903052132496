import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts';

export const userManager = new UserManager({
  authority: process.env.REACT_APP_KEYCLOAK_BASE_URL,
  client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  client_secret: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET,
  redirect_uri: process.env.REACT_APP_BASE_URL,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
});

export function getUser() {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${process.env.REACT_APP_KEYCLOAK_BASE_URL}:${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

const get = async (path, payload) => {
  const user = getUser();
  const access_token = user?.access_token;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${access_token}`,
  };
  const response = await fetch(`${process.env.REACT_APP_GATEKEEPER_BASE_URL}${path}`, {
    method: 'GET',
    headers,
    mode: 'cors',
    body: JSON.stringify(payload),
  });
  if (response.status === 401) {
    try {
      await userManager.signinSilent();
      return await this.get(path, payload);
    } catch (error) {
      const currentHash = window.location.hash;
      await userManager.signoutRedirect({
        post_logout_redirect_uri: `${process.env.REACT_APP_BASE_URL}${currentHash}`,
      });
    }
  }
  return await response.json();
};

const post = async (path, payload) => {
  const user = getUser();
  const access_token = user?.access_token;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${access_token}`,
  };
  const response = await fetch(`${process.env.REACT_APP_GATEKEEPER_BASE_URL}${path}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(payload),
    mode: 'cors',
  });
  if (response.status === 401) {
    try {
      await userManager.signinSilent();
      return await this.post(path, payload);
    } catch (error) {
      const currentHash = window.location.hash;
      await userManager.signoutRedirect({
        post_logout_redirect_uri: `${process.env.REACT_APP_BASE_URL}${currentHash}`,
      });
    }
  }
  return await response.json();
};

const deleteRequest = async (path, payload) => {
  const user = getUser();
  const access_token = user?.access_token;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${access_token}`,
  };
  const response = await fetch(`${process.env.REACT_APP_GATEKEEPER_BASE_URL}${path}`, {
    method: 'DELETE',
    headers,
    body: JSON.stringify(payload),
    mode: 'cors',
  });
  if (response.status === 401) {
    try {
      await userManager.signinSilent();
      return await this.post(path, payload);
    } catch (error) {
      const currentHash = window.location.hash;
      await userManager.signoutRedirect({
        post_logout_redirect_uri: `${process.env.REACT_APP_BASE_URL}${currentHash}`,
      });
    }
  }
  if (response.status === 204) {
    return {
      success: true,
      message: 'Request deleted successfully',
    };
  } else {
    return {
      success: false,
      message: 'Request not deleted',
    };
  }
};

export const createUser = async (sub, email) => {
  const path = `/users`;
  return await post(path, {
    kc_id: sub,
    email,
  });
};

export const deleteUser = async (sub) => {
  const path = `/users/${sub}`;
  return await deleteRequest(path, {});
};

export const getUsers = async () => {
  const path = `/users`;
  return await get(path);
};

export const findUserBySub = async (sub) => {
  const path = `/users/${sub}`;
  return await get(path);
};

export const getRoles = async () => {
  const path = `/roles`;
  return await get(path);
};

export const createRole = async (name, description) => {
  const path = `/roles`;
  return await post(path, {
    name: name,
    description: description,
  });
};

export const addUserToRole = async (sub, role_id) => {
  const path = `/roles/${role_id}/users`;
  return await post(path, {
    kc_id: sub,
  });
};

export const removeUserFromRole = async (sub, role_id) => {
  const path = `/roles/${role_id}/users/${sub}`;
  return await deleteRequest(path, {});
};

export const deleteRole = async (id) => {
  const path = `/roles/${id}`;
  return await deleteRequest(path, {});
};

export const createUserRequest = async (message, sub) => {
  const path = `/users/${sub}/requests`;
  return await post(path, {
    message: message,
  });
};

export const deleteUserRequest = async (id) => {
  const path = `/user-requests/${id}`;
  return await deleteRequest(path, {});
};

export const getGroups = async () => {
  const path = `/groups`;
  return await get(path);
};

export const createGroup = async (name, description) => {
  const path = `/groups`;
  const user = getUser();
  const sub = user?.profile?.sub;
  return await post(path, {
    kc_id: sub,
    name: name,
    description: description,
  });
};

export const deleteGroup = async (id) => {
  const path = `/groups/${id}`;
  return await deleteRequest(path, {});
};

export const addUserToGroup = async (sub, group_id) => {
  const path = `/groups/${group_id}/users`;
  return await post(path, {
    kc_id: sub,
  });
};

export const removeUserFromGroup = async (sub, group_id) => {
  const path = `/groups/${group_id}/users/${sub}`;
  return await deleteRequest(path, {});
};

export const getUserFieldsDisplaySettings = async (sub) => {
  const path = `/users/${sub}/fields`;
  return await get(path);
};

export const setUserFieldsDisplaySettings = async (sub, fields) => {
  const path = `/users/${sub}/fields`;
  return await post(path, {
    fields_id: fields,
  });
};

export const createSource = async (metaUrfms, name, description) => {
  const path = `/sources`;
  const user = getUser();
  const sub = user.profile?.sub;
  return await post(path, {
    name: name,
    description: description,
    metaUrfms: metaUrfms,
    kc_id: sub,
  });
};

export const getSources = async () => {
  const path = `/sources`;
  return await get(path);
};

export const deleteSource = async (source_id) => {
  const path = `/sources/${source_id}`;
  return await deleteRequest(path, {});
};

export const getRequests = async () => {
  const path = `/user-requests`;
  return await get(path);
};

export const getPendingRequests = async () => {
  const path = `/user-requests/pending`;
  return await get(path);
};

export const deleteRequestById = async (id) => {
  const path = `/user-requests/${id}`;
  return await deleteRequest(path, {});
};

export const updateUserRequest = async (id, is_processed) => {
  const path = `/user-requests/${id}`;
  return await post(path, {
    is_processed: is_processed,
  });
};

export const getStdFields = async () => {
  const path = `/std_fields`;
  return await get(path);
};

export const createStdField = async (
  cardinality,
  category,
  definition_and_comment,
  field_name,
  field_type,
  is_optional,
  is_public,
  obligation_or_condition,
  values,
  list_url,
  default_display_fields
) => {
  const path = `/std_fields`;
  return await post(path, {
    cardinality,
    category,
    definition_and_comment,
    field_name,
    field_type,
    isoptional: is_optional,
    ispublic: is_public,
    obligation_or_condition,
    values,
    list_url,
    default_display_fields
  });
};

export const addFieldToPolicy = async (field_id, policy_id) => {
  const path = `/policies/${policy_id}/std_fields`;
  return await post(path, {
    field_id,
  });
};

export const removeFieldFromPolicy = async (field_id, policy_id) => {
  const path = `/policies/${policy_id}/std_fields/${field_id}`;
  return await deleteRequest(path, {});
};

export const deleteAllStdFields = async () => {
  const path = `/std_fields`;
  return await deleteRequest(path, {});
};

export const getPublicFields = async () => {
  const path = `/public_std_fields`;
  return await get(path);
};

export const createPolicy = async (name) => {
  const path = `/policies`;
  const kc_id = getUser().profile?.sub;
  return await post(path, {
    name,
    kc_id,
  });
};

export const addSourceToPolicy = async (source_id, policy_id) => {
  const path = `/policies/${policy_id}/sources`;
  return await post(path, {
    source_id,
  });
};

export const removeSourceFromPolicy = async (source_id, policy_id) => {
  const path = `/policies/${policy_id}/sources/${source_id}`;
  return await deleteRequest(path, {});
};

export const addPolicyToGroup = async (policy_id, group_id) => {
  const path = `/groups/${group_id}/policies`;
  return await post(path, {
    policy_id,
  });
};

export const removePolicyFromGroup = async (policy_id, group_id) => {
  const path = `/groups/${group_id}/policies/${policy_id}`;
  return await deleteRequest(path, {});
};

export const deletePolicy = async (id) => {
  const path = `/policies/${id}`;
  return await deleteRequest(path, {});
};

export const getPolicies = async () => {
  const path = `/policies`;
  return await get(path);
};

export const getUserPolicies = async (sub) => {
  const path = `/users/${sub}/policies`;
  return await get(path);
};

export const searchQuery = async (payload) => {
  const path = `/search`;
  return await post(path, payload);
};
