import React, { useState, useEffect } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import classnames from 'classnames';
import useStyles from './styles';
import Header from '../Header';
import Sidebar from '../Sidebar';
import Home from '../../pages/home';
import Dashboard from '../../pages/dashboard';
import Users from '../../pages/users';
import Roles from '../../pages/roles/Roles';
import Requests from '../../pages/requests';
import Sources from '../../pages/sources/Sources';
import Fields from '../../pages/fields';
import Policies from '../../pages/policies';
import Groups from '../../pages/groups/Groups';
import { useLayoutState } from '../../context/LayoutContext';
import { useAuth } from 'react-oidc-context';
import { createUser, findUserBySub } from '../../services/GatekeeperService';

const ProtectedRoute = ({ isAllowed, redirectPath = '/forbidden', path, component }) => {
  if (!isAllowed) {
    return <Redirect to={redirectPath} />;
  }
  return <Route path={path} component={component} />;
};

function Layout(props) {
  const classes = useStyles();
  const layoutState = useLayoutState();
  const auth = useAuth();
  const [user, setUser] = useState(null);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const fetchUser = async (sub) => {
      let user = await findUserBySub(sub);
      if (!user.id) {
        // User registered on Keycloak but not in the database
        if (auth.user?.profile?.email) {
          const result = await createUser(sub, auth.user?.profile?.email);

          if (result) {
            user = await findUserBySub(sub);
          } else {
            auth.signoutRedirect();
          }
        }
      }
      setUser(user);
      setRoles(user.roles.map((r) => r.role_id));
    };
    if (!auth || auth.isLoading) {
      return;
    }
    if (auth.isAuthenticated) {
      fetchUser(auth.user.profile.sub);
    } else {
      auth.signinRedirect();
    }
  }, [auth]);

  return (
    user &&
    roles.length > 0 && (
      <div className={classes.root}>
        <>
          <Header email={user.email} usehistory={props.history} />
          <Sidebar roles={roles} />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/home" component={Home} />
              <ProtectedRoute
                path="/dashboard"
                isAllowed={roles.includes(1)}
                component={Dashboard}
              />
              <ProtectedRoute
                path="/users"
                isAllowed={roles.includes(1)}
                component={Users}
              />
              <ProtectedRoute
                isAllowed={roles.includes(1)}
                path="/roles"
                component={Roles}
              />
              <ProtectedRoute
                isAllowed={roles.includes(1)}
                path="/groups"
                component={Groups}
              />
              <ProtectedRoute
                isAllowed={roles.includes(1)}
                path="/requests"
                component={Requests}
              />
              <ProtectedRoute
                isAllowed={roles.includes(1)}
                path="/policies"
                component={Policies}
              />
              <ProtectedRoute
                isAllowed={roles.includes(1) || roles.includes(2)}
                path="/sources"
                component={Sources}
              />
              <ProtectedRoute
                isAllowed={roles.includes(1) || roles.includes(2)}
                path="/fields"
                component={Fields}
              />
              <Route render={() => <Redirect to="/not-found" />} />
            </Switch>
          </div>
        </>
      </div>
    )
  );
}

export default withRouter(Layout);
