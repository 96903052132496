import React, { useState, useCallback, useEffect, memo } from 'react';
import {
  EuiForm,
  EuiPageContent,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiTitle,
  EuiPageContentBody,
  EuiTabbedContent,
  EuiFormRow,
  EuiText,
  EuiFilePicker,
  EuiButton,
  EuiInMemoryTable,
} from '@elastic/eui';
import { ShowAlert } from '../../components/Common';
import Papa from 'papaparse';
import {
  getPublicFields,
  deleteAllStdFields,
  createStdField,
} from '../../services/GatekeeperService';

const renderFiles = (files) => {
  if (files && files.length > 0) {
    return (
      <ul>
        {Object.keys(files).map((item, i) => (
          <li key={i}>
            <strong>{files[item].name}</strong> ({files[item].size} bytes)
          </li>
        ))}
      </ul>
    );
  } else {
    return <p>Please, add some files to upload.</p>;
  }
};

const NewFieldsForm = memo(({ files, onFilePickerChange, onSaveField }) => {
  return (
    <>
      <EuiForm component="form">
        <EuiFormRow label="Standard field file(s)">
          <EuiFilePicker
            id="filePicker1"
            multiple
            initialPromptText="Select or drag and drop multiple source files"
            onChange={(files) => {
              onFilePickerChange(files);
            }}
            display={'large'}
          />
        </EuiFormRow>
        <EuiFormRow label="">
          <EuiText>
            <h3>Files attached</h3>
            {renderFiles(files)}
          </EuiText>
        </EuiFormRow>
        <EuiFormRow label="">
          {
            <EuiButton fill onClick={onSaveField}>
              Load
            </EuiButton>
          }
        </EuiFormRow>
      </EuiForm>
    </>
  );
});

const StdFields = memo(({ stdFields, stdFieldColumns }) => {
  return (
    <>
      <EuiForm component="form">
        <EuiFormRow label="Uploaded standard fields" fullWidth>
          <EuiInMemoryTable
            items={stdFields}
            columns={stdFieldColumns}
            search={{
              box: {
                incremental: true,
              },
            }}
            pagination={true}
            sorting={true}
          />
        </EuiFormRow>
      </EuiForm>
    </>
  );
});
let debounceTimeoutId;
let requestTimeoutId;

const Fields = (props) => {
  const [selectedTabNumber, setSelectedTabNumber] = useState(0);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('info');
  const [files, setFiles] = useState();
  const [loadedFields, setLoadedFields] = useState([]);
  const [stdFields, setStdFields] = useState([]);
  const [filteredFields, setFilteredFields] = useState([]);

  const loadStdFields = useCallback(async () => {
    const fields = await getPublicFields();
    if (fields) {
      setStdFields(fields);
      setFilteredFields(fields);
    }
  }, []);

  useEffect(() => {
    // clean up controller
    let isSubscribed = true;
    if (isSubscribed) {
      loadStdFields();
    }
    // cancel subscription to useEffect
    return () => (isSubscribed = false);
  }, [loadStdFields]);

  const onQueryChange = ({ query }) => {
    clearTimeout(debounceTimeoutId);
    debounceTimeoutId = setTimeout(() => {
      const items = stdFields.filter((field) => {
        const normalizedFieldName =
          `${field.field_name} ${field.Definition_and_comment}`.toLowerCase();
        const normalizedQuery = query.text.toLowerCase();
        return normalizedFieldName.indexOf(normalizedQuery) !== -1;
      });
      if (query.text !== '') {
        setFilteredFields(items);
      } else {
        setFilteredFields([]);
      }
    }, 300);
  };

  const onFilePickerChange = async (files) => {
    setFiles(files);
    await handleSelectedFile(files);
  };

  const handleSelectedFile = async (files) => {
    for (const file of files) {
      const reader = new FileReader();
      reader.onload = () => handleData(reader.result);
      reader.readAsText(file);
    }
  };

  const handleData = (file) => {
    if (file) {
      const result = Papa.parse(file, { header: true });
      const columns = [];
      const rows = [];
      result.meta.fields.forEach((item) => {
        const column = {
          name: item,
          options: {
            display: true,
          },
        };
        columns.push(column);
      });

      result.data.forEach((item) => {
        // lowercase the key
        Object.keys(item).forEach((key) => {
          const lowercasedKey = key.toLowerCase();
          if (key !== lowercasedKey) {
            item[lowercasedKey] = item[key];
            delete item[key];
          }
        });
        rows.push(item);
      });

      if (columns && rows) {
        setLoadedFields((preRows) => [...preRows, ...rows]);
      }
    }
  };

  const onSaveField = async () => {
    if (loadedFields) {
      await deleteAllStdFields();
      try {
        for (const field of loadedFields) {
          const {
            cardinality,
            category,
            definition_and_comment,
            field_name,
            field_type,
            isoptional,
            is_optional,
            ispublic,
            is_public,
            obligation_or_condition,
            values,
            list_url,
            default_display_fields
          } = field;

          console.log("fields: ", field);
          const isOptional = isoptional || is_optional;
          const isPublic = ispublic || is_public;
          const response = await createStdField(
            cardinality,
            category,
            definition_and_comment,
            field_name,
            field_type,
            isOptional?.toString().toLowerCase() === 'true',
            isPublic?.toString().toLowerCase() === 'true',
            obligation_or_condition,
            values,
              list_url,
              default_display_fields
          );
        }

        // Reload fields after processing
        loadStdFields();
      } catch (error) {
        console.error('Unexpected error during field saving:', error);
        setOpen(true);
        setAlertMessage('An unexpected error occurred.');
        setSeverity('error');
      }
    }
  };

  const stdFieldColumns = [
    {
      field: 'field_name',
      name: 'Field name',
      sortable: true,
      truncateText: true,
    },
    {
      field: 'field_type',
      name: 'Field type',
    },
    {
      field: 'definition_and_comment',
      name: 'Definition And comment',
      // truncateText: true,
    },
    {
      field: 'Obligation_or_condition',
      name: 'Obligation or condition',
    },
    {
      field: 'cardinality',
      name: 'Cardinality',
    },
    {
      field: 'default_display_fields',
      name: 'Default display fields',
    },
    {
      field: 'list_url',
      name: 'List URL',
    },
    {
      field: 'values',
      name: 'Values',
      sortable: true,
      truncateText: true,
      // render: date => formatDate(date, 'dobLong'),
    },
  ];

  const tabContents = [
    {
      id: 'tab1',
      name: 'Upload fields',
      content: (
        <>
          <br />
          <NewFieldsForm
            files={files}
            onFilePickerChange={onFilePickerChange}
            onSaveField={onSaveField}
          />
        </>
      ),
    },
    {
      id: 'tab2',
      name: 'Fields',
      content: (
        <>
          <br />
          <StdFields stdFields={stdFields} stdFieldColumns={stdFieldColumns} />
        </>
      ),
    },
  ];

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <EuiPageContent>
        <EuiPageContentHeader>
          <EuiPageContentHeaderSection>
            <EuiTitle>
              <h6>Field management</h6>
            </EuiTitle>
          </EuiPageContentHeaderSection>
        </EuiPageContentHeader>
        <EuiPageContentBody>
          <EuiForm>
            <EuiTabbedContent
              tabs={tabContents}
              selectedTab={tabContents[selectedTabNumber]}
              onTabClick={(tab) => {
                setSelectedTabNumber(tabContents.indexOf(tab));
                loadStdFields();
              }}
            />
          </EuiForm>
        </EuiPageContentBody>
      </EuiPageContent>
      {ShowAlert(open, handleClose, alertMessage, severity)}
    </>
  );
};

export default Fields;
