import React, { useState, useCallback, useEffect, memo } from 'react';
import { ShowAlert } from '../../components/Common/Common';
import {
  EuiForm,
  EuiPageContent,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiTitle,
  EuiPageContentBody,
  EuiTabbedContent,
  EuiFormRow,
  EuiBasicTable,
} from '@elastic/eui';
import {
  getRequests,
  getPendingRequests,
  deleteUserRequest,
  updateUserRequest,
} from '../../services/GatekeeperService';

const RequestList = memo(({ requests, requestColumns }) => {
  return (
    <>
      <EuiForm component="form">
        <EuiFormRow fullWidth>
          <EuiBasicTable
            itemId="id"
            isSelectable={true}
            items={requests}
            columns={requestColumns}
          />
        </EuiFormRow>
      </EuiForm>
    </>
  );
});

const Requests = () => {
  const [selectedTabNumber, setSelectedTabNumber] = useState(0);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('info');
  const [requests, setRequests] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);

  const loadRequests = useCallback(async () => {
    const requestList = await getRequests();
    if (requestList) {
      setRequests(requestList);
    }
  }, [getRequests]);

  const loadPendingRequests = useCallback(async () => {
    const requestList = await getPendingRequests();
    if (requestList) {
      setPendingRequests(requestList);
    }
  }, [getPendingRequests]);

  useEffect(() => {
    loadRequests();
    loadPendingRequests();
  }, [loadRequests, loadPendingRequests]);

  const onDeleteRequest = async (request) => {
    if (request) {
      const result = await deleteUserRequest(request.id);
      if (result) {
        setAlertMessage('Request has been deleted.');
        setSeverity('success');
        await loadRequests();
        await loadPendingRequests();
      } else {
        setAlertMessage(`Error: ${result.error}`);
        setSeverity('error');
      }
      setOpen(true);
    }
  };

  const onProcessRequest = async (request) => {
    if (request) {
      const result = await updateUserRequest(request.id, true);
      if (result) {
        setAlertMessage('Request has been processed.');
        setSeverity('success');
        await loadRequests();
        await loadPendingRequests();
      } else {
        setAlertMessage(`Error: ${result.error}`);
        setSeverity('error');
      }
      setOpen(true);
    }
  };

  const requestActions = [
    {
      name: 'Delete',
      description: 'Delete this request',
      icon: 'trash',
      type: 'icon',
      color: 'danger',
      onClick: onDeleteRequest,
    },
  ];

  const pendingRequestActions = [
    {
      name: 'Process',
      description: 'Process this request',
      icon: 'check',
      type: 'icon',
      color: 'danger',
      onClick: onProcessRequest,
    },
    {
      name: 'Delete',
      description: 'Delete this request',
      icon: 'trash',
      type: 'icon',
      color: 'danger',
      onClick: onDeleteRequest,
    },
  ];

  const requestColumns = [
    { field: 'request_message', name: 'Message', width: '90%' },
    { field: 'is_processed', name: 'Processed' },
    { name: 'Delete', actions: requestActions },
  ];

  const pendingRequestColumns = [
    { field: 'request_message', name: 'Message', width: '90%' },
    { field: 'is_processed', name: 'Processed' },
    { name: 'Delete', actions: pendingRequestActions },
  ];

  const tabContents = [
    {
      id: 'tab1',
      name: 'Pending requests',
      content: (
        <>
          <br />
          <RequestList
            requests={pendingRequests}
            requestColumns={pendingRequestColumns}
          />
        </>
      ),
    },
    {
      id: 'tab2',
      name: 'All requests',
      content: (
        <>
          <br />
          <RequestList requests={requests} requestColumns={requestColumns} />
        </>
      ),
    },
  ];

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <EuiPageContent>
        <EuiPageContentHeader>
          <EuiPageContentHeaderSection>
            <EuiTitle>
              <h6>Requests management</h6>
            </EuiTitle>
          </EuiPageContentHeaderSection>
        </EuiPageContentHeader>
        <EuiPageContentBody>
          <EuiForm>
            <EuiTabbedContent
              tabs={tabContents}
              selectedTab={tabContents[selectedTabNumber]}
              onTabClick={(tab) => {
                setSelectedTabNumber(tabContents.indexOf(tab));
              }}
            />
          </EuiForm>
        </EuiPageContentBody>
      </EuiPageContent>
      {ShowAlert(open, handleClose, alertMessage, severity)}
    </>
  );
};

export default Requests;
