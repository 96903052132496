import React from 'react';
import {
  EuiButton,
  EuiIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiSpacer,
} from '@elastic/eui';
import logo from '../../images/logo.png';
import useStyles from './styles';

export default function Home() {
  const classes = useStyles();
  const { REACT_APP_VALIDATOR_URL } = process.env;

  return (
    <EuiFlexGroup
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: 'calc(90vh)', textAlign: 'center' }}
    >
      {/* Logo at the top */}
      <EuiFlexItem grow={false}>
        <EuiIcon type={logo} size="original" />
      </EuiFlexItem>

      {/* Main content */}
      <EuiFlexItem>
        <div className={classes.heroContainer}>
          <EuiText>
            <h1>Welcome to the Portal</h1>
            <p>
              This is the central hub for managing users, groups, sources, policies,
              fields, and relationships between them.
            </p>
          </EuiText>
          <EuiSpacer size="l" />
          <EuiButton color="primary" size="m" href={`${REACT_APP_VALIDATOR_URL}`} target="_blank">
            <EuiIcon type="checkInCircleFilled" size="l" />
            &nbsp; Validate your JSON sources
          </EuiButton>
        </div>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
