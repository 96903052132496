import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Menu } from '@material-ui/core';
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
} from '@material-ui/icons';
import classNames from 'classnames';
import useStyles from './styles';
import { Typography } from '../Wrappers/Wrappers';
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext';
import { useAuth } from 'react-oidc-context';

export default function Header({ email }) {
  const classes = useStyles();
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const auth = useAuth();
  let [profileMenu, setProfileMenu] = useState(null);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(classes.headerIcon, classes.headerIconCollapse),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(classes.headerIcon, classes.headerIconCollapse),
              }}
            />
          )}
        </IconButton>
        <Typography variant="h6" weight="medium" className={classes.title}>
          IN-SYLVA IS administration Portal
        </Typography>
        <div className={classes.grow} />
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <div className={classes.profileMenuItem}>
              <Typography variant="h4" weight="medium">
                {email}
              </Typography>
            </div>
            <div className={classes.profileMenuItem}>
              <Typography
                className={classes.profileMenuLink}
                color="primary"
                onClick={async () => {
                  await auth.signoutRedirect({
                    post_logout_redirect_uri: `${process.env.REACT_APP_BASE_URL}`,
                  });
                }}
              >
                Sign out
              </Typography>
            </div>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
