import React, { useState, useCallback, useEffect } from 'react';
import {
  EuiForm,
  EuiPageContent,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiTitle,
  EuiPageContentBody,
  EuiTabbedContent,
} from '@elastic/eui';
import PolicyAssignment from './PolicyAssignment';
import PolicyGroupAssignment from './PolicyGroupAssignment';
import PolicySourceAssignment from './PolicySourceAssignment';
import NewPolicyForm from './NewPolicyForm';
import AssignedPolicies from './AssignedPolicies';
import { ShowAlert } from '../../components/Common';
import {
  findUserBySub,
  getSources,
  getPolicies,
  getStdFields,
  getGroups,
  createPolicy,
  deletePolicy,
  addSourceToPolicy,
  addFieldToPolicy,
  addPolicyToGroup,
  removeSourceFromPolicy,
  removeFieldFromPolicy,
  removePolicyFromGroup,
  getUser,
} from '../../services/GatekeeperService';

const Policies = () => {
  const [selectedTabNumber, setSelectedTabNumber] = useState(0);
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);
  const [policyName, setPolicyName] = useState('');
  const [selectedSource, setSelectedSource] = useState();

  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('info');

  const [optPolicies, setOptPolicies] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState();
  const [optStdFields, setOptStdFields] = useState([]);
  const [assignedPolicyItems, setAssignedPolicyItems] = useState([]);
  const [assignedSources, setAssignedSources] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [optGroupPolicies, setOptGroupPolicies] = useState([]);
  const [optGroups, setOPtGroups] = useState([]);
  const [assignedPolicyGroups, setAssignedPolicyGroups] = useState([]);
  const [optSourcePolicies, setOptSourcePolicies] = useState([]);
  const [optSources, setOptSources] = useState([]);

  const loadSources = useCallback(async () => {
    const user = await findUserBySub(getUser().profile.sub);
    const role = user.roles[0].roleid;
    let result;
    if (role === 1) {
      result = await getSources();
    } else {
      result = await getSources(); // TODO load sources for user
    }
    if (result) {
      const _sources = [];
      for (const source of result) {
        _sources.push({ value: source.id, label: source.name });
      }
      setOptSources(_sources);
    }
  }, [findUserBySub]);

  const loadPolicies = useCallback(async () => {
    const user = await findUserBySub(getUser().profile.sub);
    const role = user.roles[0].roleid;
    let result;
    if (role === 1) {
      result = await getPolicies();
    } else {
      result = await getPolicies(); // TODO load policies for user
    }
    if (result) {
      const _policies = [];
      for (const policy of result) {
        _policies.push({ value: policy.id, label: policy.name });
      }
      result = result.map((policy) => {
        return {
          id: policy.id,
          policyname: policy.name,
          sourcename: policy.sources.map((source) => source.source.name).join(', '),
        };
      });
      setPolicies(result);
      setOptPolicies(_policies);
      setOptGroupPolicies(_policies);
      setOptSourcePolicies(_policies);
    }
  }, [getPolicies]);

  const loadStdFields = useCallback(async () => {
    let result = await getStdFields();
    if (result) {
      result = result.filter((field) => !field.ispublic);
      const _fields = [];
      for (const field of result) {
        _fields.push({ value: field.id, label: field.field_name });
      }
      setOptStdFields(_fields);
    }
  }, [getStdFields]);

  const loadGroups = useCallback(async () => {
    const _groups = await getGroups();
    if (_groups) {
      const groups = [];
      for (const group of _groups) {
        groups.push({
          value: group.id,
          label: group.name,
          description: group.description,
        });
      }
      setOPtGroups(groups);
    }
  }, [getGroups]);

  useEffect(() => {
    loadSources();
    loadPolicies();
    loadStdFields();
    loadGroups();
    onSelectedPolicy([]);
    setPolicyName('');
  }, [loadGroups, loadPolicies, loadSources, loadStdFields, selectedTabNumber]);

  const onSwitchChange = () => {
    setIsSwitchChecked(!isSwitchChecked);
  };

  const onSaveNewPolicy = async (e) => {
    e.preventDefault();
    if (policyName) {
      const result = await createPolicy(policyName);
      if (result.id) {
        setAlertMessage('Policy created.');
        setSeverity('success');
        setPolicyName('');
        loadPolicies();
      } else {
        setAlertMessage('Policy not created.');
        setSeverity('error');
      }
      setOpen(true);
    }
  };

  const onDeletePolicy = async (e) => {
    if (!e.id) return;
    const response = await deletePolicy(e.id);
    if (response && response.success) {
      setAlertMessage('Policy deleted.');
      setSeverity('success');
      loadPolicies();
    } else {
      setAlertMessage('Policy not deleted.');
      setSeverity('error');
    }
    setOpen(true);
  };

  const onPolicySourceAssignment = async (e) => {
    e.preventDefault();
    const checkedPolicies = optSourcePolicies.filter((e) => {
      return e.checked === 'on';
    });

    const checkedSources = optSources.filter((e) => {
      return e.checked === 'on';
    });

    if (checkedPolicies && checkedSources) {
      for (const policy of checkedPolicies) {
        for (const source of checkedSources) {
          await addSourceToPolicy(source.value, policy.value);
        }
      }
    }
    setOptSourcePolicies(optSourcePolicies.map((e) => ({ ...e, checked: null })));
    setOptSources(optSources.map((e) => ({ ...e, checked: null })));
    loadPolicies();
  };

  const onPolicyAssignment = async (e) => {
    e.preventDefault();
    const checkedPolicies = optPolicies.filter((e) => {
      return e.checked === 'on';
    });

    const checkedStdFields = optStdFields.filter((e) => {
      return e.checked === 'on';
    });
    for (const policy of checkedPolicies) {
      for (const stdField of checkedStdFields) {
        await addFieldToPolicy(stdField.value, policy.value);
      }
    }

    setOptPolicies(optPolicies.map((e) => ({ ...e, checked: null })));
    setOptStdFields(optStdFields.map((e) => ({ ...e, checked: null })));
    loadPolicies();
  };

  const onPolicyGroupAssignment = async (e) => {
    e.preventDefault();
    const checkedPolicies = optGroupPolicies.filter((e) => {
      return e.checked === 'on';
    });

    const checkedGroups = optGroups.filter((e) => {
      return e.checked === 'on';
    });

    if (checkedPolicies && checkedGroups) {
      for (const group of checkedGroups) {
        for (const policy of checkedPolicies) {
          await addPolicyToGroup(policy.value, group.value);
        }
      }

      setOptGroupPolicies(optGroupPolicies.map((e) => ({ ...e, checked: null })));
      setOPtGroups(optGroups.map((e) => ({ ...e, checked: null })));
      loadPolicies();
    }
  };

  const onSelectedPolicy = async (e) => {
    if (e.length > 0) {
      const policies = await getPolicies();
      const selected = policies.find((policy) => {
        return policy.id === e[0].value;
      });

      const policyItems = selected.std_fields.map((field) => {
        return {
          id: field.std_field.id,
          field_name: field.std_field.field_name,
          definition_and_comment: field.std_field.definition_and_comment,
          field_type: field.std_field.field_type,
        };
      });
      setAssignedPolicyItems(policyItems);

      let assignedSources = [];
      for (const source of selected.sources) {
        let assignedSource = {};
        assignedSource.id = source.source.id;
        assignedSource.source_name = source.source.name;
        assignedSource.source_description = source.source.description;
        assignedSources.push(assignedSource);
      }
      setAssignedSources(assignedSources);

      let assignedGroups = [];
      for (const group of selected.groups) {
        //console.log(group);
        let assignedGroup = {};
        assignedGroup.id = group.group.id;
        assignedGroup.group_name = group.group.name;
        assignedGroup.group_description = group.group.description;
        assignedGroups.push(assignedGroup);
      }
      setAssignedPolicyGroups(assignedGroups);
      setSelectedPolicy(e);
    } else {
      setSelectedPolicy([]);
      setAssignedPolicyItems([]);
      setAssignedSources([]);
      setAssignedPolicyGroups([]);
    }
  };

  const onDeletePolicySource = async (e) => {
    if (e.id && selectedPolicy.length > 0) {
      const result = await removeSourceFromPolicy(e.id, selectedPolicy[0].value);
      if (result && result.success) {
        setAlertMessage('Policies-Source deleted.');
        setSeverity('success');
        onSelectedPolicy(selectedPolicy);
        loadPolicies();
      } else {
        setAlertMessage('Policies-Source not deleted.');
        setSeverity('error');
      }
      setOpen(true);
    }
  };

  const onDeletePolicyField = async (e) => {
    if (e.id && selectedPolicy.length > 0) {
      const result = await removeFieldFromPolicy(e.id, selectedPolicy[0].value);
      if (result && result.success) {
        setAlertMessage('Policies-Field deleted.');
        setSeverity('success');
        onSelectedPolicy(selectedPolicy);
        loadPolicies();
      } else {
        setAlertMessage('Policies-Field not deleted.');
        setSeverity('error');
      }
      setOpen(true);
    }
  };

  const onDeletePolicyGroup = async (e) => {
    if (e.id && selectedPolicy.length > 0) {
      const result = await removePolicyFromGroup(selectedPolicy[0].value, e.id);
      if (result && result.success) {
        setAlertMessage('Policies-Group deleted.');
        setSeverity('success');
        onSelectedPolicy(selectedPolicy);
        loadPolicies();
      } else {
        setAlertMessage('Policies-Group not deleted.');
        setSeverity('error');
      }
      setOpen(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const tabContents = [
    {
      id: 'tab1',
      name: 'New policy',
      content: (
        <>
          <br />
          <NewPolicyForm
            policyName={policyName}
            setPolicyName={setPolicyName}
            isSwitchChecked={isSwitchChecked}
            setIsSwitchChecked={setIsSwitchChecked}
            onSwitchChange={onSwitchChange}
            selectedSource={selectedSource}
            setSelectedSource={setSelectedSource}
            onSaveNewPolicy={onSaveNewPolicy}
            onDeletePolicy={onDeletePolicy}
            policies={policies}
          />
        </>
      ),
    },
    {
      id: 'tab2',
      name: 'Policy-Source assignment',
      content: (
        <>
          <br />
          <PolicySourceAssignment
            optPolicies={optSourcePolicies}
            setOptPolicies={setOptSourcePolicies}
            optSources={optSources}
            setOptSources={setOptSources}
            onPolicySourceAssignment={onPolicySourceAssignment}
          />
        </>
      ),
    },
    {
      id: 'tab3',
      name: 'Policy-Field assignment',
      content: (
        <>
          <br />
          <PolicyAssignment
            optPolicies={optPolicies}
            setOptPolicies={setOptPolicies}
            optStdFields={optStdFields}
            setOptStdFields={setOptStdFields}
            onPolicyAssignment={onPolicyAssignment}
          />
        </>
      ),
    },
    {
      id: 'tab4',
      name: 'Policy-Group assignment',
      content: (
        <>
          <br />
          <PolicyGroupAssignment
            optPolicies={optGroupPolicies}
            setOptPolicies={setOptGroupPolicies}
            optGroups={optGroups}
            setOPtGroups={setOPtGroups}
            onPolicyGroupAssignment={onPolicyGroupAssignment}
          />
        </>
      ),
    },
    {
      id: 'tab5',
      name: 'Assigned policies',
      content: (
        <>
          <br />
          <AssignedPolicies
            policies={optPolicies}
            selectedPolicy={selectedPolicy}
            onSelectedPolicy={onSelectedPolicy}
            assignedPolicySources={assignedSources}
            assignedPolicyFields={assignedPolicyItems}
            assignedPolicyGroups={assignedPolicyGroups}
            onDeletePolicySource={onDeletePolicySource}
            onDeletePolicyField={onDeletePolicyField}
            onDeletePolicyGroup={onDeletePolicyGroup}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <EuiPageContent>
        <EuiPageContentHeader>
          <EuiPageContentHeaderSection>
            <EuiTitle>
              <h6>Policies management</h6>
            </EuiTitle>
          </EuiPageContentHeaderSection>
        </EuiPageContentHeader>
        <EuiPageContentBody>
          <EuiForm>
            <EuiTabbedContent
              tabs={tabContents}
              selectedTab={tabContents[selectedTabNumber]}
              onTabClick={(tab) => {
                setSelectedTabNumber(tabContents.indexOf(tab));
              }}
            />
          </EuiForm>
        </EuiPageContentBody>
      </EuiPageContent>
      {ShowAlert(open, handleClose, alertMessage, severity)}
    </>
  );
};

export default Policies;
