import React, { useState, useEffect } from 'react';
import { Drawer, IconButton, List } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import useStyles from './styles';
import SidebarLink from './components/SidebarLink/SidebarLink';
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext';
import { getSideBarItems } from '../../utils';

const Sidebar = ({ location, roles }) => {
  const classes = useStyles();
  const theme = useTheme();
  let { isSidebarOpened } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  let [isPermanent, setPermanent] = useState(true);
  const [sidebarItems, setSidebarItems] = useState([]);

  useEffect(() => {
    setSidebarItems(getSideBarItems());
    const handleWindowWidthChange = () => {
      const windowWidth = window.innerWidth;
      const breakpointWidth = theme.breakpoints.values.md;
      const isSmallScreen = windowWidth < breakpointWidth;

      if (isSmallScreen && isPermanent) {
        setPermanent(false);
      } else if (!isSmallScreen && !isPermanent) {
        setPermanent(true);
      }
    };
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  }, [setSidebarItems, isPermanent, theme]);

  const buildSidebarItems = () => {
    return sidebarItems.map((item) => {
      if (item.roles.some((authorizedRoleId) => roles.includes(authorizedRoleId))) {
        return (
          <SidebarLink
            key={item.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...item}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{ root: classNames(classes.headerIcon, classes.headerIconCollapse) }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>{buildSidebarItems()}</List>
    </Drawer>
  );
};

export default withRouter(Sidebar);
