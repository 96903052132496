import React, { memo } from 'react';
import {
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiButton,
  EuiSpacer,
  EuiBasicTable,
} from '@elastic/eui';

const NewPolicyForm = memo(
  ({ policyName, setPolicyName, onSaveNewPolicy, onDeletePolicy, policies }) => {
    const policyColumns = [
      {
        field: 'policyname',
        name: 'Policy name',
      },
      {
        field: 'sourcename',
        name: 'Source name',
      },
      {
        name: 'Actions',
        actions: [
          {
            name: 'Delete',
            description: 'Delete this policy',
            icon: 'trash',
            type: 'icon',
            color: 'danger',
            onClick: onDeletePolicy,
          },
        ],
      },
    ];
    return (
      <>
        <EuiForm component="form">
          <EuiFormRow label="Policy name">
            <EuiFieldText
              value={policyName}
              onChange={(e) => setPolicyName(e.target.value)}
            />
          </EuiFormRow>
          <EuiSpacer />
          <EuiButton type="submit" onClick={onSaveNewPolicy} fill>
            Save
          </EuiButton>
          <EuiSpacer />
          <EuiFormRow label="" fullWidth>
            <EuiBasicTable items={policies} rowheader="Name" columns={policyColumns} />
          </EuiFormRow>
        </EuiForm>
      </>
    );
  }
);

export default NewPolicyForm;
