import React from 'react';
import { Grid, LinearProgress } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import keycloakLogo from '../../images/Keycloak_Logo.svg';
import mongoLogo from '../../images/mongo_logo.svg';
import {
  ResponsiveContainer,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import {
  EuiButton,
  EuiCard,
  EuiIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
} from '@elastic/eui';
import useStyles from './styles';
import Widget from '../../components/Widget';
import { Typography } from '../../components/Wrappers';
import Dot from '../../components/Sidebar/components/Dot';
import { getRandomData } from '../../utils';

const PieChartData = [
  { name: 'super-admin', value: 400, color: 'primary' },
  { name: 'source-manager', value: 300, color: 'secondary' },
  { name: 'normal-user', value: 300, color: 'warning' },
];

const urlMaps = new Map();

urlMaps.set(
  'kibana',
  window._env_ ? window._env_.REACT_APP_IN_SYLVA_KIBANA_URL : 'http://localhost:5601/'
);
urlMaps.set(
  'postgresql',
  window._env_
    ? window._env_.REACT_APP_IN_SYLVA_POSTGRESQL_URL
    : 'http://localhost:5050/login?next=%2F'
);
urlMaps.set(
  'mongoDb',
  window._env_ ? window._env_.REACT_APP_IN_SYLVA_MONGODB_URL : 'http://localhost:8881/'
);
urlMaps.set(
  'elasticsearch',
  window._env_
    ? window._env_.REACT_APP_IN_SYLVA_ELASTICSEARCH_URL
    : 'http://localhost:9200/'
);
urlMaps.set(
  'keycloak',
  window._env_
    ? window._env_.REACT_APP_IN_SYLVA_KEYCLOAK_URL
    : 'http://localhost:7000/keycloak/auth'
);
urlMaps.set(
  'portainer',
  window._env_
    ? window._env_.REACT_APP_IN_SYLVA_PORTAINER_URL
    : 'http://localhost:9000/#/init/admin'
);

export default function Dashboard() {
  const classes = useStyles();
  const theme = useTheme();
  const {
    REACT_APP_KIBANA_URL,
    REACT_APP_PGADMIN_URL,
    REACT_APP_KEYCLOAK_URL,
    REACT_APP_PORTAINER_URL,
    REACT_APP_MONGO_EXPRESS_URL,
  } = process.env;

  return (
    <>
      <EuiFlexGroup gutterSize="l">
        <EuiFlexItem>
          <EuiCard
            icon={<EuiIcon size="xxl" type="logoKibana" />}
            title="Kibana"
            description="Access to Kibana."
            footer={
              <div>
                <EuiButton
                  aria-label="Go to Kibana"
                  href={`${REACT_APP_KIBANA_URL}`}
                  target="_blank"
                >
                  Go for it
                </EuiButton>
                <EuiSpacer size="xs" />
              </div>
            }
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            icon={<EuiIcon size="xxl" type={mongoLogo} />}
            title="MongoDB"
            description="Access to MongoDB"
            footer={
              <div>
                <EuiButton
                  aria-label="Go to MongoDB"
                  href={`${REACT_APP_MONGO_EXPRESS_URL}`}
                  target="_blank"
                >
                  Go for it
                </EuiButton>
                <EuiSpacer size="xs" />
              </div>
            }
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            icon={<EuiIcon size="xxl" type="logoPostgres" />}
            title="PgAdmin"
            description="Access to PgAdmin"
            footer={
              <div>
                <EuiButton
                  aria-label="Go to PgAdmin"
                  href={`${REACT_APP_PGADMIN_URL}`}
                  target="_blank"
                >
                  Go for it
                </EuiButton>
                <EuiSpacer size="xs" />
              </div>
            }
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            icon={<EuiIcon type={keycloakLogo} size="xxl" />}
            title="Keycloak"
            description="Access to Keycloak"
            footer={
              <div>
                <EuiButton
                  aria-label="Go to Keycloak"
                  href={`${REACT_APP_KEYCLOAK_URL}`}
                  target="_blank"
                >
                  Go for it
                </EuiButton>
                <EuiSpacer size="xs" />
              </div>
            }
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            icon={<EuiIcon size="xxl" type="logoDocker" />}
            title="Portainer"
            description="Access to Portainer"
            footer={
              <div>
                <EuiButton
                  aria-label="Go to Portainer"
                  href={`${REACT_APP_PORTAINER_URL}`}
                  target="_blank"
                >
                  Go for it
                </EuiButton>
                <EuiSpacer size="xs" />
              </div>
            }
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <Grid container spacing={4}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Widget
            title="Visits today"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div className={classes.visitsNumberContainer}>
              <Typography size="xl" weight="medium">
                12, 678
              </Typography>
              <LineChart
                width={55}
                height={30}
                data={[
                  { value: 10 },
                  { value: 15 },
                  { value: 10 },
                  { value: 17 },
                  { value: 18 },
                ]}
                margin={{ left: theme.spacing(2) }}
              >
                <Line
                  type="natural"
                  dataKey="value"
                  stroke={theme.palette.success.main}
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </div>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography color="text" colorBrightness="secondary">
                  Registrations
                </Typography>
                <Typography size="md">860</Typography>
              </Grid>
              <Grid item>
                <Typography color="text" colorBrightness="secondary">
                  Sign out
                </Typography>
                <Typography size="md">32</Typography>
              </Grid>
              <Grid item>
                <Typography color="text" colorBrightness="secondary">
                  Rate
                </Typography>
                <Typography size="md">3.25%</Typography>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={3} md={8} sm={6} xs={12}>
          <Widget
            title="Query performance"
            upperTitle
            className={classes.card}
            bodyClass={classes.fullHeightBody}
          >
            <div className={classes.performanceLegendWrapper}>
              <div className={classes.legendElement}>
                <Dot color="warning" />
                <Typography
                  color="text"
                  colorBrightness="secondary"
                  className={classes.legendElementText}
                >
                  Integration
                </Typography>
              </div>
              <div className={classes.legendElement}>
                <Dot color="primary" />
                <Typography
                  color="text"
                  colorBrightness="secondary"
                  className={classes.legendElementText}
                >
                  SDK
                </Typography>
              </div>
            </div>
            <div className={classes.progressSection}>
              <Typography
                size="md"
                color="text"
                colorBrightness="secondary"
                className={classes.progressSectionTitle}
              >
                Integration
              </Typography>
              <LinearProgress
                variant="determinate"
                value={30}
                classes={{ barColorPrimary: classes.progressBar }}
                className={classes.progress}
              />
            </div>
            <div>
              <Typography
                size="md"
                color="text"
                colorBrightness="secondary"
                className={classes.progressSectionTitle}
              >
                SDK
              </Typography>
              <LinearProgress
                variant="determinate"
                value={55}
                classes={{ barColorPrimary: classes.progressBar }}
                className={classes.progress}
              />
            </div>
          </Widget>
        </Grid>
        <Grid item lg={3} md={8} sm={6} xs={12}>
          <Widget
            title="Server overview"
            upperTitle
            className={classes.card}
            bodyClass={classes.fullHeightBody}
          >
            <div className={classes.serverOverviewElement}>
              <Typography
                color="text"
                colorBrightness="secondary"
                className={classes.serverOverviewElementText}
              >
                60% / 37°С / 3.3 Ghz
              </Typography>
              <div className={classes.serverOverviewElementChartWrapper}>
                <ResponsiveContainer height={50} width="99%">
                  <AreaChart data={getRandomData(10)}>
                    <Area
                      type="natural"
                      dataKey="value"
                      stroke={theme.palette.secondary.main}
                      fill={theme.palette.secondary.light}
                      strokeWidth={2}
                      fillOpacity="0.25"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className={classes.serverOverviewElement}>
              <Typography
                color="text"
                colorBrightness="secondary"
                className={classes.serverOverviewElementText}
              >
                54% / 31°С / 3.3 Ghz
              </Typography>
              <div className={classes.serverOverviewElementChartWrapper}>
                <ResponsiveContainer height={50} width="99%">
                  <AreaChart data={getRandomData(10)}>
                    <Area
                      type="natural"
                      dataKey="value"
                      stroke={theme.palette.primary.main}
                      fill={theme.palette.primary.light}
                      strokeWidth={2}
                      fillOpacity="0.25"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className={classes.serverOverviewElement}>
              <Typography
                color="text"
                colorBrightness="secondary"
                className={classes.serverOverviewElementText}
              >
                57% / 21°С / 3.3 Ghz
              </Typography>
              <div className={classes.serverOverviewElementChartWrapper}>
                <ResponsiveContainer height={50} width="99%">
                  <AreaChart data={getRandomData(10)}>
                    <Area
                      type="natural"
                      dataKey="value"
                      stroke={theme.palette.warning.main}
                      fill={theme.palette.warning.light}
                      strokeWidth={2}
                      fillOpacity="0.25"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
          </Widget>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Widget title="Number of users" upperTitle className={classes.card}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ResponsiveContainer width="100%" height={144}>
                  <PieChart margin={{ left: theme.spacing(2) }}>
                    <Pie
                      data={PieChartData}
                      innerRadius={45}
                      outerRadius={60}
                      dataKey="value"
                    >
                      {PieChartData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={theme.palette[entry.color].main}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.pieChartLegendWrapper}>
                  {PieChartData.map(({ name, value, color }, index) => (
                    <div key={color} className={classes.legendItemContainer}>
                      <Dot color={color} />
                      <Typography style={{ whiteSpace: 'nowrap' }}>
                        &nbsp;{name}&nbsp;
                      </Typography>
                      <Typography color="text" colorBrightness="secondary">
                        &nbsp;{value}
                      </Typography>
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
