import React, { useState, Fragment, useCallback, useEffect } from 'react';
import { ShowAlert } from '../../components/Common';
import {
  EuiForm,
  EuiPageContent,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiTitle,
  EuiPageContentBody,
  EuiTabbedContent,
  EuiFormRow,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiButton,
  EuiBasicTable,
  EuiSelectable,
  EuiComboBox,
} from '@elastic/eui';
import {
  getRoles,
  getUsers,
  createRole,
  addUserToRole,
  deleteRole,
} from '../../services/GatekeeperService';

const newRoleForm = ({
  roleNameValue,
  setRoleNameValue,
  roleDescriptionValue,
  setRoleDescriptionValue,
  roles,
  onSaveRole,
  roleColumns,
}) => {
  return (
    roles &&
    roles.length > 0 && (
      <>
        <EuiForm component="form">
          <EuiFormRow label="Name">
            <EuiFieldText
              value={roleNameValue || ''}
              onChange={(e) => setRoleNameValue(e.target.value)}
            />
          </EuiFormRow>
          <EuiFormRow label="Description">
            <EuiFieldText
              value={roleDescriptionValue || ''}
              onChange={(e) => setRoleDescriptionValue(e.target.value)}
            />
          </EuiFormRow>
          <EuiSpacer />
          {
            <EuiButton fill onClick={onSaveRole}>
              Save
            </EuiButton>
          }
          <EuiSpacer />
          <EuiFormRow label="" fullWidth>
            <EuiBasicTable items={roles} rowheader="Name" columns={roleColumns} />
          </EuiFormRow>
        </EuiForm>
      </>
    )
  );
};

const roleAssignment = (roles, setRoles, users, setUsers, onRoleAssignment) => {
  return (
    roles &&
    users &&
    roles.length > 0 &&
    users.length > 0 && (
      <>
        <EuiForm component="form">
          <EuiFlexGroup component="span">
            <EuiFlexItem component="span">
              <EuiFormRow label="Roles" fullWidth>
                <EuiSelectable
                  searchable
                  singleSelection={true}
                  options={roles}
                  onChange={(newOptions) => setRoles(newOptions)}
                >
                  {(list, search) => (
                    <Fragment>
                      {search}
                      {list}
                    </Fragment>
                  )}
                </EuiSelectable>
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem component="span">
              <EuiFormRow label="Users" fullWidth>
                <EuiSelectable
                  searchable
                  options={users}
                  onChange={(newOptions) => setUsers(newOptions)}
                >
                  {(list, search) => (
                    <Fragment>
                      {search}
                      {list}
                    </Fragment>
                  )}
                </EuiSelectable>
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />

          <EuiButton type="submit" onClick={onRoleAssignment} fill>
            Save
          </EuiButton>
        </EuiForm>
      </>
    )
  );
};
const assignedRoles = ({
  roles,
  selectedRole,
  onSelectedRole,
  assignedRoleToUsers,
  assignedRolesColumns,
}) => {
  return (
    <>
      <EuiForm component="form">
        <EuiFormRow label="Select specific roles">
          <EuiComboBox
            placeholder="Select a role"
            singleSelection={true}
            options={roles}
            selectedOptions={selectedRole}
            onChange={(e) => {
              onSelectedRole(e);
            }}
          />
        </EuiFormRow>
        <EuiFormRow label="Assigned roles" fullWidth>
          <EuiBasicTable
            items={assignedRoleToUsers}
            rowheader=""
            columns={assignedRolesColumns}
          />
        </EuiFormRow>
      </EuiForm>
    </>
  );
};

const Roles = () => {
  const [selectedTabNumber, setSelectedTabNumber] = useState(0);
  const [roleNameValue, setRoleNameValue] = useState();
  const [roleDescriptionValue, setRoleDescriptionValue] = useState();
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);

  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('info');
  const [open, setOpen] = useState(false);

  const [selectedRole, setSelectedRole] = useState();
  const [assignedRoleToUsers, setAssignedRoleToUsers] = useState([]);

  const loads = useCallback(async () => {
    const roles = await getRoles();
    const users = await getUsers();

    if (roles && users) {
      const _roles = [];
      for (const role of roles) {
        _roles.push({
          value: role.id,
          label: role.name,
          description: role.description,
          users: role.users,
        });
      }
      setRoles(_roles);

      const _users = [];
      for (const user of users) {
        _users.push({ value: user.id, label: user.email, sub: user.kc_id });
      }
      setUsers(_users);
    }
  }, []);

  useEffect(() => {
    setSelectedRole([]);
    setAssignedRoleToUsers([]);
    loads();
  }, [loads, selectedTabNumber]);

  useEffect(() => {
    loads();
  }, [loads]);

  const onSaveRole = async (e) => {
    e.preventDefault();
    const result = await createRole(roleNameValue, roleDescriptionValue);
    if (result) {
      setAlertMessage('Role has been created.');
      setSeverity('success');
    } else {
      setAlertMessage('Role has not been created.');
      setSeverity('error');
    }
    setOpen(true);
    setRoleNameValue('');
    setRoleDescriptionValue('');
    await loads();
  };

  const onRoleAssignment = async (e) => {
    e.preventDefault();
    const checkedRoles = roles.filter((e) => {
      return e.checked === 'on';
    });

    const checkedUsers = users.filter((e) => {
      return e.checked === 'on';
    });

    for (const user of checkedUsers) {
      for (const role of checkedRoles) {
        await addUserToRole(user.sub, role.value);
      }
    }
    await loads();
    setRoles(roles.map((role) => ({ ...role, checked: null })));
    setUsers(users.map((user) => ({ ...user, checked: null })));
  };

  const removeUserFromRole = async (e) => {
    const result = await removeUserFromRole(e.sub, e.roleId);
    if (result) {
      setAlertMessage('User has been removed from role.');
      setSeverity('success');
    } else {
      setAlertMessage('User has not been removed from role.');
      setSeverity('error');
    }
    setOpen(true);
    onSelectedRole([]);
    await loads();
  };

  const onSelectedRole = async (e) => {
    if (e.length > 0) {
      setSelectedRole(e);
      const assignedUserByRoleItems = roles
        .find((role) => {
          return role.value === e[0].value;
        })
        .users.map((user) => {
          return {
            email: user.user.email,
            rolename: e[0].label,
            roleId: e[0].value,
            sub: user.user.kc_id,
          };
        });
      setAssignedRoleToUsers(assignedUserByRoleItems);
    } else {
      setSelectedRole([]);
      setAssignedRoleToUsers([]);
    }
  };

  const onDeleteRole = async (e) => {
    const result = await deleteRole(e.value);
    if (result) {
      setAlertMessage('Role has been deleted.');
      setSeverity('success');
    } else {
      setAlertMessage('Role has not been deleted.');
      setSeverity('error');
    }
    setOpen(true);
    await loads();
  };

  const roleActions = [
    {
      name: 'Delete',
      description: 'Delete this role',
      icon: 'trash',
      type: 'icon',
      color: 'danger',
      onClick: onDeleteRole,
    },
  ];

  const assignedRoleActions = [
    {
      name: 'Remove',
      description: 'Remove this user from this role',
      icon: 'trash',
      type: 'icon',
      color: 'danger',
      onClick: removeUserFromRole,
    },
  ];

  const roleColumns = [
    { field: 'label', name: 'Name' },
    { field: 'description', name: 'Description' },
    { name: 'Actions', actions: roleActions },
  ];

  const assignedRolesColumns = [
    { field: 'email', name: 'E-mail' },
    { field: 'rolename', name: 'Role' },
    { name: 'Actions', actions: assignedRoleActions },
  ];

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const tabContents = [
    {
      id: 'tab1',
      name: 'New role',
      content: (
        <>
          <br />
          {newRoleForm({
            roleNameValue,
            setRoleNameValue,
            roleDescriptionValue,
            setRoleDescriptionValue,
            roles,
            onSaveRole,
            roleColumns,
          })}
        </>
      ),
    },
    {
      id: 'tab2',
      name: 'Role assignment',
      content: (
        <>
          <br />
          {roleAssignment(roles, setRoles, users, setUsers, onRoleAssignment)}
        </>
      ),
    },
    {
      id: 'tab3',
      name: 'Assigned roles',
      content: (
        <>
          <br />
          {assignedRoles({
            roles,
            selectedRole,
            onSelectedRole,
            assignedRoleToUsers: assignedRoleToUsers,
            assignedRolesColumns,
          })}
        </>
      ),
    },
  ];

  return (
    <>
      <EuiPageContent>
        <EuiPageContentHeader>
          <EuiPageContentHeaderSection>
            <EuiTitle>
              <h6>Roles management</h6>
            </EuiTitle>
          </EuiPageContentHeaderSection>
        </EuiPageContentHeader>
        <EuiPageContentBody>
          <EuiForm>
            <EuiTabbedContent
              tabs={tabContents}
              selectedTab={tabContents[selectedTabNumber]}
              onTabClick={(tab) => {
                setSelectedTabNumber(tabContents.indexOf(tab));
              }}
            />
          </EuiForm>
        </EuiPageContentBody>
      </EuiPageContent>
      {ShowAlert(open, handleClose, alertMessage, severity)}
    </>
  );
};

export default Roles;
