import React, { memo } from 'react';
import { EuiBasicTable, EuiComboBox, EuiForm, EuiFormRow } from '@elastic/eui';

const AssignedPolicies = memo(
  ({
    policies,
    selectedPolicy,
    onSelectedPolicy,
    assignedPolicySources,
    assignedPolicyFields,
    assignedPolicyGroups,
    onDeletePolicySource,
    onDeletePolicyField,
    onDeletePolicyGroup,
  }) => {
    const assignedPolicyColumns = [
      {
        field: 'field_name',
        name: 'Field name',
        sortable: true,
      },
      {
        field: 'definition_and_comment',
        name: 'Definition and comment',
        truncateText: true,
        mobileOptions: {
          show: false,
        },
      },
      {
        field: 'field_type',
        name: 'Field type',
      },
      {
        name: 'Actions',
        actions: [
          {
            name: 'Delete',
            description: 'Delete this policies-field',
            icon: 'trash',
            type: 'icon',
            color: 'danger',
            onClick: async (e) => {
              await onDeletePolicyField(e);
            },
          },
        ],
      },
    ];

    const assignedPolicyGroupColumns = [
      { field: 'group_name', name: 'Group name' },
      { field: 'group_description', name: 'Group description' },
      {
        name: 'Actions',
        actions: [
          {
            name: 'Delete',
            description: 'Delete this policies-group',
            icon: 'trash',
            type: 'icon',
            color: 'danger',
            onClick: async (e) => {
              await onDeletePolicyGroup(e);
            },
          },
        ],
      },
    ];

    const assignedPolicySourceColumns = [
      {
        field: 'source_name',
        name: 'Source name',
      },
      {
        field: 'source_description',
        name: 'Source description',
      },
      {
        name: 'Actions',
        actions: [
          {
            name: 'Delete',
            description: 'Delete this policy-source',
            icon: 'trash',
            type: 'icon',
            color: 'danger',
            onClick: async (e) => {
              await onDeletePolicySource(e);
            },
          },
        ],
      },
    ];
    return (
      <>
        <EuiForm component="form">
          <EuiFormRow label="Select a specific policy">
            <EuiComboBox
              placeholder="Select a policy"
              singleSelection={true}
              options={policies}
              selectedOptions={selectedPolicy}
              onChange={(e) => {
                onSelectedPolicy(e);
              }}
            />
          </EuiFormRow>
          <EuiFormRow label="Assigned sources" fullWidth>
            <EuiBasicTable
              items={assignedPolicySources}
              columns={assignedPolicySourceColumns}
            />
          </EuiFormRow>
          <EuiFormRow label="Assigned standard fields" fullWidth>
            <EuiBasicTable items={assignedPolicyFields} columns={assignedPolicyColumns} />
          </EuiFormRow>
          <EuiFormRow label="Assigned groups" fullWidth>
            <EuiBasicTable
              items={assignedPolicyGroups}
              columns={assignedPolicyGroupColumns}
            />
          </EuiFormRow>
        </EuiForm>
      </>
    );
  }
);

export default AssignedPolicies;
