import React from 'react';
import { EuiIcon } from '@elastic/eui';

// Function used to mock statistics for dashboard page
export const getRandomData = (arrayLength) => {
  return Array(arrayLength)
    .fill()
    .map(() => {
      return { value: Math.floor(Math.random() * 10) + 1 };
    });
};

export const safeJsonStringify = (str) => {
  try {
    return JSON.stringify(str);
  } catch (e) {
    return null;
  }
};

export const safeJsonParse = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
};

export const getSideBarItems = () => {
  return [
    {
      id: 0,
      label: 'Home',
      link: '/home',
      roles: [1, 2, 3],
      icon: <EuiIcon type="home" size="l" />,
    },
    {
      id: 1,
      label: 'Dashboard',
      link: '/dashboard',
      roles: [1],
      icon: <EuiIcon type="dashboardApp" size="l" />,
    },
    {
      id: 2,
      label: 'Users',
      link: '/users',
      roles: [1],
      icon: <EuiIcon type="user" size="l" />,
    },
    {
      id: 3,
      label: 'Users roles',
      link: '/roles',
      roles: [1],
      icon: <EuiIcon type="usersRolesApp" size="l" />,
    },
    {
      id: 4,
      label: 'Groups',
      link: '/groups',
      roles: [1],
      icon: <EuiIcon type="users" size="l" />,
    },
    {
      id: 5,
      label: 'Requests',
      link: '/requests',
      roles: [1],
      icon: <EuiIcon type="email" size="l" />,
    },
    {
      id: 6,
      label: 'Policies',
      link: '/policies',
      roles: [1],
      icon: <EuiIcon type="lockOpen" size="l" />,
    },
    {
      id: 7,
      label: 'Sources',
      link: '/sources',
      roles: [1, 2],
      icon: <EuiIcon type="indexManagementApp" size="l" />,
    },
    {
      id: 8,
      label: 'Fields',
      link: '/fields',
      roles: [1, 2],
      icon: <EuiIcon type="visTable" size="l" />,
    },
  ];
};
